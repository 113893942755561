<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-card-title class="text-h5"> ¿Desea salir? </v-card-title>
      <v-card-text>Los cambios realizados seran descartados</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="close"> Cancelar </v-btn>
        <v-btn color="green darken-1" text @click="redirectToVisitors">
          Salir
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogConfirm",
  props: ["open"],
  data() {
    return {
      dialog: this.open,
    };
  },
  methods: {
    redirectToVisitors() {
      this.$router.push({ name: "visitors" });
    },
    close() {
      this.$emit("close", false);
    },
  },
  watch: {
    open(value) {
      this.dialog = value;
    },
  },
};
</script>
